import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

function FootNav({from}) {
  return (
    <>
   <footer className="footer">
      <div className="footer-container">
        <div className="footer-links">
          <Link to="/">About</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/mission.html">Mission</Link>
          <Link to="/Demo">Demo</Link>
          <Link to="/press">Press</Link>
          <Link to="/blog.html">Blog</Link>
          <Link to="/privacy">Privacy</Link>
          <Link to="/terms">Terms of Service</Link>
        </div>
      </div>
    </footer>
    </>
  );
}

export default FootNav;