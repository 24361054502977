import React, { useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoMdLink } from "react-icons/io";
import ModalImage from "react-modal-image";
import { useSelector } from "react-redux";
import { Server2 } from "../../redux/actions/auth/auth.actions";
import axios from "axios";
import moment from "moment/moment";
import { FiX } from "react-icons/fi";

const PopupShare = ({
  handleClose,
  Url,
  isOpen,
  isHours,
  setIsHours,
  setIsOpen,
}) => {
  const userData = useSelector((state) => state?.auth?.user?.user);
  const [copyText, setCopyText] = useState(false);
  const [loading, setLoading] = useState(false);
  // Image Selection Start
  const [Images, setImages] = useState([]);
  const [files, setfiles] = useState([]);
  console.log(files, "files");
  const [EventDayOn, setEventDayOn] = useState(true);
  // const [InputPhoto, setInputPhoto] = useState("");
  const [IsUpload, setIsUpload] = useState(false);
  const [showRes, setShowRes] = useState("");
  // const [selectedTimezone, setSelectedTimezone] = useState(
  //   Intl.DateTimeFormat().resolvedOptions().timeZone
  // );
  const timezones =
    Intl.supportedValuesOf && Intl.supportedValuesOf("timeZone")
      ? Intl.supportedValuesOf("timeZone")
      : [
        "UTC",
        "America/New_York",
        "America/Los_Angeles",
        "Asia/Kolkata",
        "Europe/London",
        "Europe/Berlin",
        "Australia/Sydney",
        // Add more timezones if needed
      ];

  const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const inputFile = useRef(null);

  // to open file explore
  const openInputFile = (e) => {
    inputFile.current.click();
  };
  // to display image
  const handleImageChange = (e) => {
    setShowRes("");
    inputFile.current.click();
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      setfiles((prev) => [...prev, ...files]);
      const imageUrls = files.map((file) => URL.createObjectURL(file));
      setImages((prev) => [...prev, ...imageUrls]);

      setIsUpload(true);
    }
  };
  const UploadImage = () => {
    setLoading(true);
    let data = new FormData();
    files.forEach((file, index) => {
      data.append(`file${index}`, file);
    });
    data.append(
      "albumName",
      userData.type === true ? "Business-Gallery" : "Personal-Gallery"
    );
    data.append("user", userData?._id);
    let config = {
      method: "post",
      url: Server2 + "/users/upload-gallery-images",
      data: data,
    };
    axios(config)
      .then(function (response) {
        setShowRes("Uploaded Sucessfully");
        console.log(response.data);
        setLoading(false);
        setTimeout(() => {
          setShowRes("");
          handleClose();
        }, 3000);
      })
      .catch(function (err) {
        console.log(err);
        setLoading(false);
        setShowRes("Failed to upload");
        setTimeout(() => {
          setShowRes("");
        }, 1000);
      });
  };
  // Opening Hours
  const [BusinessDay, setBusinessDay] = useState("Monday");
  const [EventSTime, setEventSTime] = useState("09:00");
  const [EventETime, setEventETime] = useState("21:00");
  const [error, setError] = useState("");
  const date = new Date(`2022-03-30T${EventSTime}`);
  const date2 = new Date(`2022-03-30T${EventETime}`);
  const EventStart = moment(date).format("hh:mm A");
  const EventEnd = moment(date2).format("hh:mm A");
  const UploadApiOpeningHour = async () => {
    var data = {
      id: userData._id,
      day: BusinessDay,
      opening: EventStart,
      closing: EventEnd,
      isOff: !EventDayOn,
      timeZone: selectedTimezone,
    };

    console.log(data, "data...");
    let config = {
      method: "POST",
      url: Server2 + "/users/business-hours",
      data: data,
    };
    await axios(config)
      .then((response) => {
        console.log(response.data, "resData");
        if (response.data.user.status === 200) {
          setError("Successfully updated");
        }
        setTimeout(() => {
          setError("");
        }, 3000);
      })
      .catch((err) => {
        console.log(err, "error upload opening hours");
        setError("Ops! not updated");
        setTimeout(() => {
          setError("");
        }, 3000);
      });
  };
  return (
    <div className="popup-box">
      <div
        className="box"
        style={{
          marginTop: isOpen === true ? "calc(100vh - 80vh - 20px)" : "100px",
          padding: isOpen === true ? "40px 20px" : "20px",
        }}
      >
        {isOpen === true ? (
          isHours === 1 ? (
            <>
              <h5 className="ms-4 ps-1 my-3">Share Profile</h5>
              <div>
                <input
                  name="text"
                  value={Url}
                  disabled
                  className="form-control"
                  style={{ width: "90%", height: "45px", margin: "10px 5%" }}
                />
              </div>
              {/* <div>{Url.length> 10? `${Url.substring(0, 35)}...` :Url}</div> */}
              <div
                className=""
                style={{
                  width: "90%",
                  margin: "25px 5%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <CopyToClipboard text={Url}>
                  <button
                    onClick={() => setCopyText(true)}
                    className={
                      copyText === true
                        ? "bs-gray-400 text-primary rounded-3"
                        : "bg-white text-primary rounded-3"
                    }
                    style={{
                      border: "0.5px solid grey",
                      fontSize: "14px",
                      width: "23%",
                      padding: "10px",
                    }}
                  >
                    <IoMdLink size={20} />{" "}
                    {copyText === true ? "Copied" : "Copy Link"}
                  </button>
                </CopyToClipboard>
                <button
                  className="bg-primary text-white rounded-3 border-0"
                  style={{ fontSize: "14px", width: "23%", padding: "10px" }}
                  onClick={() => {
                    handleClose();
                    setIsHours(1);
                  }}
                >
                  Done
                </button>
              </div>
            </>
          ) : (
            <>
              <h4 className="my-3 text-center text-bold text-dark">
                Business Hours
              </h4>
              {error.length > 0 ? (
                <h5
                  className="text-success text-center"
                  style={{ fontSize: "16px" }}
                >
                  {error}
                </h5>
              ) : null}
              <div className="w-100">
                <div className="container d-flex flex-column align-items-center">
                  <div
                    className="d-flex justify-content-between"
                    style={{ width: "350px" }}
                  >
                    <span className="fs-5 text-dark">Select Business Day</span>
                    <div className="d-flex justify-content-between align-items-center rounded-3">
                      <label className="privacy-label pe-2">
                        {EventDayOn === true ? "is Open" : "is Closed"}
                      </label>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={EventDayOn}
                          onChange={(e) => setEventDayOn(e.target.checked)}
                        ></input>
                        <span className="slider"></span>
                      </label>
                    </div>
                  </div>

                  <select
                    className="form-select"
                    style={{
                      width: "65%",
                      backgroundColor: "#ECECEC",
                      backgroundImage: "none",
                      color: "black",
                    }}
                    name="day"
                    value={BusinessDay}
                    onChange={(e) => setBusinessDay(e.target.value)}
                  >
                    <option value="Monday" className="p-2 text-dark">
                      Monday
                    </option>
                    <option value="Tuesday" className="p-2 text-dark">
                      Tuesday
                    </option>
                    <option value="Wednesday" className="p-2 text-dark">
                      Wednesday
                    </option>
                    <option value="Thursday" className="p-2 text-dark">
                      Thursday
                    </option>
                    <option value="Friday" className="p-2 text-dark">
                      Friday
                    </option>
                    <option value="Saturday" className="p-2 text-dark">
                      Saturday
                    </option>
                    <option value="Sunday" className="p-2 text-dark">
                      Sunday
                    </option>
                  </select>

                  {EventDayOn === true ? (
                    <div className="text-start" style={{ width: '65%' }}>
                      <p
                        className="fs-5 text-dark mt-2"
                        style={{ marginBottom: "2px" }}
                      >
                        Opening time
                      </p>
                      <input
                        name="STime"
                        id="STime"
                        type="time"
                        style={{
                          borderColor:
                            EventSTime.length > 0
                              ? "#ECECEC"
                              : error === "stime"
                                ? "red"
                                : "#ECECEC",
                          width: "100%",
                          backgroundColor: "#ECECEC",
                        }}
                        placeholder="HH : MM"
                        className="form-control"
                        aria-label="text"
                        value={EventSTime}
                        onChange={(e) => setEventSTime(e.target.value)}
                      />

                      <p
                        className="fs-5 text-dark mt-2"
                        style={{ marginBottom: "2px" }}
                      >
                        Closing time
                      </p>
                      <input
                        name="ETime"
                        id="ETime"
                        type="time"
                        style={{
                          borderColor:
                            EventETime.length > 0
                              ? "#ECECEC"
                              : error === "stime"
                                ? "red"
                                : "#ECECEC",
                          width: "100%",
                          backgroundColor: "#ECECEC",
                        }}
                        placeholder="HH : MM"
                        className="form-control"
                        aria-label="text"
                        value={EventETime}
                        onChange={(e) => setEventETime(e.target.value)}
                      />
                      <p
                        className="fs-5 text-dark mt-2"
                        style={{ marginBottom: "2px" }}
                      >
                        Selected Timezone: {selectedTimezone}
                      </p>
                      <select
                        className="form-select"
                        value={selectedTimezone}
                        onChange={(e) => setSelectedTimezone(e.target.value)}
                        style={{
                          borderColor:
                            EventETime.length > 0
                              ? "#ECECEC"
                              : error === "stime"
                                ? "red"
                                : "#ECECEC",
                          width: "100%",
                          backgroundColor: "#ECECEC",
                        }}
                      >
                        {timezones.map((tz) => (
                          <option key={tz} value={tz}>
                            {tz}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <>
                      <p className="pt-4">{BusinessDay}s are Off</p>
                    </>
                  )}

                  <div
                    className=""
                    style={{
                      width: "80%",
                      margin: "25px 0%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      className="bg-primary text-white rounded-3 border-0 mx-4"
                      style={{
                        fontSize: "14px",
                        width: "23%",
                        padding: "10px",
                      }}
                      onClick={() => UploadApiOpeningHour()}
                    >
                      Update
                    </button>
                    <button
                      className="bg-primary text-white rounded-3 border-0 mx-4"
                      style={{
                        fontSize: "14px",
                        width: "23%",
                        padding: "10px",
                      }}
                      onClick={() => {
                        handleClose();
                        setIsHours(1);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </>
          )
        ) : (
          <div className="d-flex justify-content-center m-auto">
            <FiX
              color="black"
              size={25}
              style={{
                zIndex: 101,
                borderRadius: 10,
                position: "absolute",
                top: "4px",
                right: "10px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
            <div>
              <h5 className="ms-5 ps-5 my-2">Update Gallery</h5>
              {showRes.length > 0 && (
                <h5
                  className="text-success ms-5 ps-5"
                  style={{ fontSize: "12px" }}
                >
                  {showRes}
                </h5>
              )}
              {IsUpload ? (
                <div
                  className="d-flex flex-wrap justify-content-center border p-2"
                  style={{
                    maxHeight: "350px",
                    overflowY: "auto",
                    cursor: "pointer",
                  }}
                  onClick={openInputFile}
                >
                  {Images.map((image, index) => (
                    <div key={index} className="position-relative">
                      <FiX
                        color="black"
                        size={20}
                        style={{
                          zIndex: 101,
                          borderRadius: 10,
                          position: "absolute",
                          backgroundColor: "#ECECEC",
                          padding: "4px",
                          top: "4px",
                          right: "10px",
                          cursor: "pointer",
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          const newImages = Images.filter(
                            (img) => img !== image
                          );
                          setImages(newImages);
                          const newFiles = files.filter(
                            (file) => file !== files[index]
                          );
                          setfiles(newFiles);
                          if (newImages.length === 0) {
                            setIsUpload(false);
                          }
                        }}
                      />
                      <div onClick={(event) => event.stopPropagation()}>
                        <ModalImage
                          key={index}
                          hideDownload
                          className="gallary-upload m-2"
                          small={image}
                          large={image}
                          alt={`Gallery Image ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <img
                    src="/img/Placeholder.jpg"
                    alt="upload demo"
                    className="gallary-upload2"
                    style={{ cursor: "pointer" }}
                    onClick={openInputFile}
                  />
                </div>
              )}
              <input
                type="file"
                id="file"
                className="event-photo d-none"
                ref={inputFile}
                accept="image/png, image/jpeg"
                multiple
                onChange={handleImageChange}
              />
              <div
                className="my-3"
                style={{
                  // width: "340px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  type="button"
                  className="border-0 rounded-2 mx-2 w-100"
                  data-bs-toggle="button"
                  style={{
                    backgroundColor: "#0d6efd",
                    color: "white",
                    height: "45px",
                  }}
                  disabled={loading || files.length === 0}
                  onClick={() => {
                    UploadImage(files);
                  }}
                >
                  {loading ? (
                    <span>
                      <span className="spinner-border spinner-border-sm me-2"></span>{" "}
                      Uploading...
                    </span>
                  ) : (
                    "Upload"
                  )}
                </button>
                <button
                  type="button"
                  className="border-0 chat-btn rounded-2 mx-2 w-100"
                  data-bs-toggle="button"
                  style={{
                    color: "white",
                    height: "45px",
                  }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PopupShare;