import  React,{ useState } from 'react';
import { MdAdd } from 'react-icons/md';
import ImageViewer from "react-simple-image-viewer";
import { useCallback } from 'react';
import { Server2 } from '../redux/actions/auth/auth.actions';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { FiX } from 'react-icons/fi';

const GalleryProfile = ({GallaryImg , setIsOpenGallary , setGallaryRender , id}) => {
    const prams = window.location.pathname;
    const userData = useSelector((state) => state?.auth?.user?.user)
  // model states
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const deleteImg = (e) =>{
    let data = JSON.stringify({
      user: userData?._id,
      url: e,
    });

    var config = {
      method: "post",
      url: Server2 + "/users/delete-gallery-image ",
      headers: { "Content-Type": "application/json" },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if(response.data.message === "Image Deleted Successfuly"){
          setGallaryRender(true)
          setTimeout(() =>{
            setGallaryRender(false)
          },1000)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <>
    {isViewerOpen && (
      <ImageViewer
        src={GallaryImg}
        currentIndex={currentImage}
        disableScroll={true}
        closeOnClickOutside={true}
        onClose={closeImageViewer}
      />
    )}
    <div className="d-block d-xxl-block d-xl-block d-lg-block">
      <span className="fw-bold fs-6 ">Image Gallery</span>
      <div className="d-flex mt-2">
        <div className="d-flex Gal-Main py-1">
          {GallaryImg.length === 0
            ?prams.includes("/Profiless") || prams === `/BussinesProfile/_id=${id}` ?
              <p className=" fs-6 text-capitalize rounded text-center pt-1" style={{ backgroundColor: "#ececec", width: "105px", height: "86px", marginBottom: "-1px" }}>No image found in gallery</p>:null
            : GallaryImg.map((imgs, index) => {
                return (
                  <div className='position-relative' key={index}>
                  {prams === `/${userData?.type?"business":"personal"}/${userData?._id}`?
                  <FiX color='white' size={16} style={{
                    backgroundColor:"#6148a1",
                    borderRadius:10,
                    position:"absolute",
                    top:"-4px",
                    right:"10px",
                    cursor:"pointer",
                    padding:"2px"
                  }} className='shadow' onClick={() => deleteImg(imgs)} />
                  :null}
                  <img
                    onClick={() => openImageViewer(index)}
                    key={index}
                    className="rounded-3 img-gal"
                    src={imgs}
                    alt={"Images Gallary"}
                  />
                  </div>
                );
              }).reverse()}
              
          {isViewerOpen && (
            <ImageViewer
              style={{ zIndex: "1000" }}
              src={GallaryImg}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          )}
        </div>
        {prams === `/${userData?.type?"business":"personal"}/${userData?._id}`?
        <div
          className="position-relative ms-3 me-3 py-1 "
          onClick={() => setIsOpenGallary(true)}
          style={{
            cursor: "pointer",
          }}
        >
          <div
            className=" rounded-3"
            style={{
              width: "105px",
              height: "86px",
              border: "1px solid #6148a1",
              backgroundColor: "#ececec",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "22%",
              left: "27px",
            }}
          >
            <MdAdd
              size={20}
              color="black"
              style={{ marginLeft: "17px" }}
            />
            <br />
            <span className="fw-bold lh-1 title text-dark">
              Add More
            </span>
          </div>
        </div>:""
        }
      </div>
    </div>
      
    </>
  )
}

export default GalleryProfile
